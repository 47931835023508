
      const customCSSPropertiesMap: Record<string,string> = {
        "maxContentWidth": "--awsui-max-content-width-7t5ed6",
"minContentWidth": "--awsui-min-content-width-7t5ed6",
"breadcrumbsGap": "--awsui-breadcrumbs-gap-7t5ed6",
"contentGapLeft": "--awsui-content-gap-left-7t5ed6",
"contentGapRight": "--awsui-content-gap-right-7t5ed6",
"contentHeight": "--awsui-content-height-7t5ed6",
"contentLayoutDefaultHorizontalPadding": "--awsui-content-layout-default-horizontal-padding-7t5ed6",
"contentLayoutMaxContentWidth": "--awsui-content-layout-max-content-width-7t5ed6",
"contentLayoutMainGap": "--awsui-content-layout-main-gap-7t5ed6",
"defaultMaxContentWidth": "--awsui-default-max-content-width-7t5ed6",
"defaultMinContentWidth": "--awsui-default-min-content-width-7t5ed6",
"drawerSize": "--awsui-drawer-size-7t5ed6",
"footerHeight": "--awsui-footer-height-7t5ed6",
"headerGap": "--awsui-header-gap-7t5ed6",
"headerHeight": "--awsui-header-height-7t5ed6",
"layoutWidth": "--awsui-layout-width-7t5ed6",
"mainGap": "--awsui-main-gap-7t5ed6",
"mainOffsetLeft": "--awsui-main-offset-left-7t5ed6",
"mainTemplateRows": "--awsui-main-template-rows-7t5ed6",
"mobileBarHeight": "--awsui-mobile-bar-height-7t5ed6",
"notificationsGap": "--awsui-notifications-gap-7t5ed6",
"notificationsHeight": "--awsui-notifications-height-7t5ed6",
"offsetTop": "--awsui-offset-top-7t5ed6",
"overlapHeight": "--awsui-overlap-height-7t5ed6",
"navigationWidth": "--awsui-navigation-width-7t5ed6",
"splitPanelReportedHeaderSize": "--awsui-split-panel-reported-header-size-7t5ed6",
"splitPanelReportedSize": "--awsui-split-panel-reported-size-7t5ed6",
"splitPanelHeight": "--awsui-split-panel-height-7t5ed6",
"splitPanelMinWidth": "--awsui-split-panel-min-width-7t5ed6",
"splitPanelMaxWidth": "--awsui-split-panel-max-width-7t5ed6",
"toolsMaxWidth": "--awsui-tools-max-width-7t5ed6",
"toolsWidth": "--awsui-tools-width-7t5ed6",
"toolsAnimationStartingOpacity": "--awsui-tools-animation-starting-opacity-7t5ed6",
"contentScrollMargin": "--awsui-content-scroll-margin-7t5ed6",
"flashbarStackDepth": "--awsui-flashbar-stack-depth-7t5ed6",
"flashbarStackIndex": "--awsui-flashbar-stack-index-7t5ed6",
"flashbarStickyBottomMargin": "--awsui-flashbar-sticky-bottom-margin-7t5ed6",
"stackedNotificationsBottomMargin": "--awsui-stacked-notifications-bottom-margin-7t5ed6",
"stackedNotificationsDefaultBottomMargin": "--awsui-stacked-notifications-default-bottom-margin-7t5ed6",
"dropdownDefaultMaxWidth": "--awsui-dropdown-default-max-width-7t5ed6",
"spinnerRotatorFrom": "--awsui-spinner-rotator-from-7t5ed6",
"spinnerRotatorTo": "--awsui-spinner-rotator-to-7t5ed6",
"spinnerLineLeftFrom": "--awsui-spinner-line-left-from-7t5ed6",
"spinnerLineLeftTo": "--awsui-spinner-line-left-to-7t5ed6",
"spinnerLineRightFrom": "--awsui-spinner-line-right-from-7t5ed6",
"spinnerLineRightTo": "--awsui-spinner-line-right-to-7t5ed6",
"sliderLabelCount": "--awsui-slider-label-count-7t5ed6",
"sliderTickCount": "--awsui-slider-tick-count-7t5ed6",
"sliderReferenceColumn": "--awsui-slider-reference-column-7t5ed6",
"sliderNextReferenceColumn": "--awsui-slider-next-reference-column-7t5ed6",
"sliderMaxStart": "--awsui-slider-max-start-7t5ed6",
"sliderMinEnd": "--awsui-slider-min-end-7t5ed6",
"sliderRangeInlineSize": "--awsui-slider-range-inline-size-7t5ed6",
"sliderTooltipPosition": "--awsui-slider-tooltip-position-7t5ed6",
"togglesLeftWidth": "--awsui-toggles-left-width-7t5ed6",
"togglesRightWidth": "--awsui-toggles-right-width-7t5ed6",
      };
      export default customCSSPropertiesMap;
    